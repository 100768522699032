import React from 'react';

import SEO from '../../components/seo';
import Contact from '../../components/contact';
import Navigation from '../../components/navigation';
import { Languages } from '../../tools/languages';

const EnglishContactPage = ({ location, language = Languages.EN }) => {
  const pathName = location.pathname;

  return (
    <>
      <SEO pathName={pathName} title="Contact" lang={language} />
      <Navigation />
      <main>
        <Contact />
      </main>
    </>
  );
};

export default EnglishContactPage;
